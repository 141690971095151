import { useState } from 'react';
import { PopupButton } from '@typeform/embed-react';
import {
  object, string, func, bool,
} from 'prop-types';
import { navigate } from 'gatsby';
import { constants } from '../../../config';
import * as styles from './index.module.scss';
import * as selectStyles from '../../common/custom-select/select.module.scss';
import { Wrap } from '../../sections-wrap';
import { Select } from '../../common/custom-select';

const { sizeTypeFormPopUp } = constants;

function QuestionsHeader({
  categories, onSearch, currentCategory, questionType, isAll,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [isQuestionsOpen, setIsQuestionsOpen] = useState(false);
  const questionsValues = ['Answered questions', 'Unanswered questions'];

  const getDirectionFromValue = (value) => {
    switch (value) {
      case 'Answered questions':
        return 'answered';
      case 'Unanswered questions':
        return 'unanswered';
      default:
        return '';
    }
  };

  const getValueFromDirection = (direction) => {
    switch (direction) {
      case 'answered':
        return 'Answered questions';
      case 'unanswered':
        return 'Unanswered questions';
      default:
        return '';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchValue);
  };
  const redirect = (uri) => {
    window.location.href = uri;
  };
  const redirectQuestionType = (value) => {
    if (currentCategory) {
      window.location.href = value ? currentCategory.uri + value : currentCategory.uri;
    }
    if (isAll) {
      navigate(`/questions/${value}`);
    }
  };
  return (
    <div className={styles.pageContainer}>
      <Wrap>
        <h1 className={styles.title}>Questions</h1>
        <div>
          <div className={styles.filtersContainer}>
            <div className={styles.questionTypeFilter}>
              <Select
                label="All questions"
                value={getValueFromDirection(questionType)}
                firstOption="All questions"
                onChangeFirstOption={() => redirectQuestionType('')}
                isOpen={isQuestionsOpen}
                setIsOpen={setIsQuestionsOpen}
                showWordSelect={false}
              >
                {questionsValues.map((item) => (
                  <button
                    onClick={() => redirectQuestionType(getDirectionFromValue(item))}
                    type="button"
                    className={item === questionType ? selectStyles.selected : selectStyles.item}
                  >
                    <p className={selectStyles.text}>{item}</p>
                  </button>
                ))}
              </Select>
            </div>
            <div className={styles.categoryFilter}>
              <Select
                label="All categories"
                value={currentCategory.name}
                firstOption="All categories"
                onChangeFirstOption={() => redirect('/questions')}
                showWordSelect={false}
              >
                {categories?.map((item) => (
                  <button
                    onClick={() => redirect(item.uri)}
                    type="button"
                    className={item.name === currentCategory.name
                      ? selectStyles.selected
                      : selectStyles.item}
                  >
                    <p className={selectStyles.text}>{item.name}</p>
                  </button>
                ))}
              </Select>
            </div>
            <form className={styles.searchFilter} onSubmit={handleSubmit}>
              <input
                className={styles.input}
                type="text"
                placeholder="Search Questions..."
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button className={styles.button} type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30">
                  <path d="M28,27.23 L21.12,20.08 C25.0814718,15.3634032 24.7035301,8.38214002 20.2560223,4.12077656 C15.8085145,-0.140586889 8.81747829,-0.219883151 4.27445761,3.93950467 C-0.26856307,8.0988925 -0.804757956,15.069787 3.04871182,19.8750253 C6.9021816,24.6802635 13.8231059,25.6710627 18.87,22.14 L25.8,29.35 C26.1787015,29.7429922 26.738295,29.9040971 27.267987,29.772628 C27.7976789,29.6411589 28.2169966,29.2370892 28.367987,28.712628 C28.5189774,28.1881667 28.3787016,27.6229921 28,27.23 Z M12.2,4 C16.9606945,4 20.82,7.85930546 20.82,12.62 C20.82,17.3806945 16.9606945,21.24 12.2,21.24 C7.43930546,21.24 3.58,17.3806945 3.58,12.62 C3.58551061,7.8615899 7.4415899,4.00551061 12.2,4 Z" />
                </svg>
              </button>
            </form>
          </div>
        </div>
        <div className={styles.askLawyerButton}>

          <PopupButton
            className={styles.button}
            size={sizeTypeFormPopUp}
            id={process.env.GATSBY_ASK_LAWYER_FORM_ID}
            type="button"
          >
            Ask a Lawyer
          </PopupButton>
        </div>

      </Wrap>
    </div>
  );
}

QuestionsHeader.propTypes = {
  categories: object,
  currentCategory: string,
  questionType: string,
  onSearch: func,
  isAll: bool,
};

QuestionsHeader.defaultProps = {
  categories: {},
  currentCategory: '',
  questionType: '',
  onSearch: null,
  isAll: false,
};

export { QuestionsHeader };
