import { func, string } from 'prop-types';
import * as styles from './search-button.module.scss';
import searchBlack from '../../../assets/icons/search-black.svg';

function SearchButton({
  stateSelected, categorySelected, setData, setIsLoaded, value, setValue, setApiError,
}) {
  const query = async (api, filters) => {
    try {
      const response = await window
        .fetch(
          `/api/${api}${filters}`,
          {
            method: 'GET',
          },
        );
      const data = await response.json();
      return data;
    } catch (error) {
      setApiError(true);
    }
    return {};
  };

  const getQuestionsAndAnswersThreads = async () => {
    if (value) {
      const threads = await query(
        'getContentfulThreadsQA',
        `?state=${stateSelected}&category=${categorySelected}&search=${value}`,
      );
      const threadsItems = threads.items;
      const threadsItemsFields = threadsItems?.map((thread) => thread.fields);
      return threadsItemsFields;
    }
    return {};
  };

  const getSomethingById = async (data, field, contentModelNeeded, propModelNeeded) => {
    if (data) {
      const idsToGet = data.map((item) => item[field]?.sys?.id);
      const distinctsIds = Array.from(new Set(idsToGet));
      const distinctsIdsAsString = distinctsIds.join(',');
      if (distinctsIdsAsString) {
        const responseAuthors = await query(contentModelNeeded, `?id=${distinctsIdsAsString}`);
        return responseAuthors ? responseAuthors[propModelNeeded] : {};
      }
    }
    return [];
  };

  const getDataQueryingApis = async () => {
    const threads = await getQuestionsAndAnswersThreads();
    if (threads?.length) {
      const authors = await getSomethingById(threads, 'author', 'getAttorneys', 'items');
      const fieldsAuthors = authors?.map((author) => author.fields);
      const authorsComplete = await getSomethingById(fieldsAuthors, 'attorney', 'getComponentAttorneys', 'items');
      const fieldsAuthorsComplete = authorsComplete?.map((author) => author.fields);
      const imagesAuthors = await getSomethingById(fieldsAuthorsComplete, 'authorImage', 'getImages', 'items');
      const fieldsImagesAuthors = imagesAuthors?.map((image) => image.fields);
      const assetsImages = await getSomethingById(fieldsImagesAuthors, 'image', 'getAssets', 'items');

      return {
        assetsImages, imagesAuthors, authorsComplete, authors, threads,
      };
    }
    return {
      assetsImages: [], imagesAuthors: [], authorsComplete: [], authors: [], threads: [],
    };
  };

  const mergingTwoListsByProp = (fstList, sndList, prop, existFieldsProp = true) => {
    const ret = [];
    fstList?.forEach((itemFstList) => {
      const dataObject = existFieldsProp ? itemFstList.fields : itemFstList;
      const itemFound = sndList.find(
        (itemSndList) => itemSndList.id === dataObject[prop].sys.id,
      );
      const newItem = { ...dataObject, id: itemFstList?.sys?.id };
      newItem[prop] = itemFound;
      ret.push(newItem);
    });

    return ret;
  };

  async function onSubmit(e) {
    e.preventDefault();
    setIsLoaded(true);
    // Getting the data
    if (value) {
      const {
        assetsImages, imagesAuthors, authorsComplete, authors, threads,
      } = await getDataQueryingApis();
      // Merging objects to provide all of data needed in just one

      const imageWithAssets = [];
      imagesAuthors?.forEach((image) => {
        const imageAssets = assetsImages.find(
          (asset) => asset.sys.id === image.fields.image.sys.id,
        );
        imageWithAssets.push({ ...image.fields, image: imageAssets?.fields, id: image.sys.id });
      });

      const authorCompleteWithImage = mergingTwoListsByProp(authorsComplete, imageWithAssets, 'authorImage');
      const authorWithAuthorComplete = mergingTwoListsByProp(authors, authorCompleteWithImage, 'attorney');
      const threadWithAuthor = mergingTwoListsByProp(threads, authorWithAuthorComplete, 'author', false);
      setData(threadWithAuthor);
    } else {
      setData('');
    }
  }
  return (
    <div className={styles.searchContainer}>
      <form onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="search">
          <input
            className={styles.searchInput}
            type="search"
            placeholder="Search…"
            id="search"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </label>
        <button className={styles.searchButton} type="button" onClick={(e) => onSubmit(e)}>
          <img src={searchBlack} alt="like" />
        </button>
      </form>
    </div>
  );
}

SearchButton.propTypes = {
  stateSelected: string,
  categorySelected: string,
  setData: func,
  setIsLoaded: func,
  value: string,
  setValue: func,
  setApiError: func,
};

SearchButton.defaultProps = {
  stateSelected: '',
  categorySelected: '',
  setData: null,
  setIsLoaded: null,
  value: '',
  setValue: null,
  setApiError: null,
};

export { SearchButton };
