import { graphql, useStaticQuery, navigate } from 'gatsby';
import { func, string } from 'prop-types';
import { SearchButton } from '../../questions-and-answers/search/search-button';
import { Select } from '../../common/custom-select';
import * as styles from './index.module.scss';
import * as selectStyles from '../../common/custom-select/select.module.scss';

function QuestionsFilters({
  stateSelected, categorySelected, setData, setIsLoaded, value, setValue,
  setApiError,
}) {
  const handleRedirect = (filter, kindOfFilter) => {
    const formattedFilter = filter ? filter.toLowerCase().replace(/\s+/g, '-') : '';
    const formattedStateSelected = stateSelected ? stateSelected.toLowerCase().replace(/\s+/g, '-') : '';
    const formattedCategorySelected = categorySelected ? categorySelected.toLowerCase().replace(/\s+/g, '-') : '';
    let url = '/questions/';
    if (kindOfFilter === 'state' && formattedFilter) {
      url += `${formattedFilter}/`;
    } else if (formattedStateSelected && kindOfFilter !== 'state') {
      url += `${formattedStateSelected}/`;
    }
    if (kindOfFilter === 'category' && formattedFilter) {
      url += `${formattedFilter}/`;
    } else if (formattedCategorySelected && kindOfFilter !== 'category') {
      url += `${formattedCategorySelected}/`;
    }
    navigate(url);
  };

  const filtersData = useStaticQuery(graphql`
  {
    allContentfulPageQa {
      categories: group(field: category) {
        fieldValue
      }
      states: group(field: state) {
        fieldValue
      }
    }
  }
`);
  let { categories, states } = filtersData.allContentfulPageQa;
  categories = categories.map((category) => category.fieldValue);
  states = states.map((state) => state.fieldValue);
  return (
    <div className={styles.filtersContainer}>
      <Select
        value={categorySelected}
        label="Category"
        firstOption="All categories"
        onChangeFirstOption={() => handleRedirect('', 'category')}
      >
        {categories?.map((item) => (
          <button
            onClick={() => handleRedirect(item, 'category')}
            type="button"
            key={item}
            className={item === categorySelected
              ? selectStyles.selected
              : selectStyles.item}
          >
            <p className={selectStyles.text}>{item}</p>
          </button>
        ))}
      </Select>
      <Select
        value={stateSelected}
        label="State"
        firstOption="All states"
        onChangeFirstOption={() => handleRedirect('', 'state')}
      >
        {states?.map((item) => (
          <button
            onClick={() => handleRedirect(item, 'state')}
            key={item}
            type="button"
            className={item === stateSelected
              ? selectStyles.selected
              : selectStyles.item}
          >
            <p className={selectStyles.text}>{item}</p>
          </button>
        ))}
      </Select>
      <SearchButton
        stateSelected={stateSelected}
        categorySelected={categorySelected}
        setData={setData}
        setIsLoaded={setIsLoaded}
        value={value}
        setValue={setValue}
        setApiError={setApiError}
      />
    </div>
  );
}

QuestionsFilters.propTypes = {
  stateSelected: string,
  categorySelected: string,
  setData: func,
  setIsLoaded: func,
  value: string,
  setValue: func,
  setApiError: func,
};

QuestionsFilters.defaultProps = {
  stateSelected: '',
  categorySelected: '',
  setData: null,
  setIsLoaded: null,
  value: '',
  setValue: null,
  setApiError: null,
};

export { QuestionsFilters };
