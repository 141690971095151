import { func, node, string } from 'prop-types';
import * as styles from './index.module.scss';
import cross from '../../../assets/icons/grey-cross.svg';
import { Button } from '../button';

function Modal({
  title, mainText, onDismiss, onAccept, dismissButtonText, acceptButtonText, children,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.positioning}>
        <div className={styles.heading}>
          {title && <h1 className={styles.title}>{title}</h1>}
          <button type="button" onClick={onDismiss} className={styles.exitButton}>
            <img className={styles.icon} src={cross} alt="close message icon" />
          </button>
        </div>
        <div className={styles.body}>
          {children || <section>{mainText}</section>}
        </div>
        {(dismissButtonText || acceptButtonText) && (
          <div className={styles.footer}>
            {dismissButtonText && (
              <Button
                buttonStyle="secondaryAlimony"
                className={styles.dismissButton}
                text={dismissButtonText}
                onClick={onDismiss}
              />
            )}
            {acceptButtonText && (
              <Button
                buttonStyle="primaryAlimony"
                className={styles.acceptButton}
                text={acceptButtonText}
                onClick={onAccept}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Modal.defaultProps = {
  children: undefined,
  title: '',
  mainText: '',
  onDismiss: () => null,
  onAccept: () => null,
  dismissButtonText: '',
  acceptButtonText: '',
};

Modal.propTypes = {
  title: string,
  mainText: string,
  onDismiss: func,
  onAccept: func,
  dismissButtonText: string,
  acceptButtonText: string,
  children: node,
};

export { Modal };
