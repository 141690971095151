import {
  array, bool, func, object, string,
} from 'prop-types';
import { QuestionsFilters } from './questions-filters';
import { Wrap } from '../../sections-wrap';
import { Question, Pagination } from '../../questions-and-answers';
import * as styles from './index.module.scss';

function QuestionsContent({
  threads, dataSearched, stateSelected, categorySelected, pagination, setData, setIsLoaded, value,
  setValue, setApiError,
}) {
  return (
    <Wrap>
      <div className={styles.questionsContainer}>
        <div className={styles.headerSection}>
          <span className={styles.title}>Past Questions</span>
          <QuestionsFilters
            stateSelected={stateSelected}
            categorySelected={categorySelected}
            setData={setData}
            setIsLoaded={setIsLoaded}
            value={value}
            setValue={setValue}
            setApiError={setApiError}
          />
        </div>
        <article className={styles.entry}>
          {threads?.length ? (
            <div className={styles.threads}>
              {threads?.map((thread) => (
                <Question key={thread.slug} data={thread} />
              ))}
              {!dataSearched && (
              <Pagination
                pagination={pagination}
                stateSelected={stateSelected}
                categorySelected={categorySelected}
              />
              )}
            </div>
          ) : (<p className={styles.notFound}>No questions found.</p>) }
        </article>
      </div>
    </Wrap>
  );
}

QuestionsContent.propTypes = {
  threads: array,
  dataSearched: bool,
  stateSelected: string,
  categorySelected: string,
  pagination: object,
  setData: func,
  setIsLoaded: func,
  value: string,
  setValue: func,
  setApiError: func,
};

QuestionsContent.defaultProps = {
  threads: [],
  dataSearched: false,
  stateSelected: '',
  categorySelected: '',
  pagination: {},
  setData: null,
  setIsLoaded: null,
  value: '',
  setValue: null,
  setApiError: null,
};

export { QuestionsContent };
