import { useState } from 'react';
import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Modal } from '../components/common/modal';
import { QuestionsContent } from '../components/questions';
import { Spinner } from '../components/spinner';
import { Header, Disclaimer } from '../components/questions-and-answers';
import * as styles from './templates.module.scss';

function Questions({ data, pageContext }) {
  const [threads, setThreads] = useState(data?.allContentfulPageQa?.nodes);
  const [dataSearched, setDataSearched] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState('');
  const [apiError, setApiError] = useState(false);

  const handlingQueryFilter = (queryResponse) => {
    if (queryResponse) {
      setDataSearched(true);
      // a lot of items could be displayed, we can't implement pagination to the searched elements
      setThreads(queryResponse.slice(0, 20));
      setIsLoaded(false);
    } else {
      setDataSearched(false);
      setThreads(data?.allContentfulPageQa?.nodes);
      setIsLoaded(false);
    }
  };
  const {
    stateSelected, categorySelected, pagination,
  } = pageContext;
  return (
    <>
      {apiError && (
      <>
        <div className={styles.modalContainer}>
          <Modal
            acceptButtonText="Continue"
            mainText="Please try again later or contact us for assistance."
            title="Your search could not be completed due to an error."
            onDismiss={() => setApiError(false)}
            onAccept={() => setApiError(false)}
          />
        </div>
        <div className={styles.background} />
      </>
      )}
      <div className={styles.questionsContainer}>
        <Header
          titleText={`
              If you need help or have a question,
              <br />
              we're here for you.
            `}
          displayFAQText
        />
        {isLoaded ? (
          <div className={styles.spinnerContainer}>
            <Spinner className={styles.spinner} />
          </div>
        ) : (
          <QuestionsContent
            threads={threads}
            dataSearched={dataSearched}
            stateSelected={stateSelected}
            categorySelected={categorySelected}
            pagination={pagination}
            setIsLoaded={setIsLoaded}
            setData={handlingQueryFilter}
            value={value}
            setValue={setValue}
            setApiError={setApiError}
          />
        )}
        <Disclaimer disclaimerText={data?.contentfulPageQaSettings?.disclaimer?.disclaimer} />
      </div>
    </>
  );
}

export const query = graphql`
  query ($categories: [String], $states: [String], $limit: Int, $skip: Int){
    allContentfulPageQa(
        sort: {order: DESC, fields: askedDate}
        filter: {category: {in: $categories}, state: {in: $states}}
        limit: $limit
        skip: $skip 
      ){
      nodes {
        slug
        category
        askedDate
        answer {
          raw
        }
        question {
          question
        }
        author {
          title
          uri
          attorney {
            authorImage {
              image {
                gatsbyImage(width:35, placeholder: NONE)
              }
              altText
            }
            firstName
            lastName
          }
        }
      }
    }
    contentfulPageQaSettings(id: {eq: "12b0c95c-87d6-5514-ab0d-8d9373e64ae5"}) {
      disclaimer {
        disclaimer
      }
    }
  }
`;

Questions.propTypes = {
  data: object,
  pageContext: object,
};

Questions.defaultProps = {
  data: {},
  pageContext: {},
};

export default Questions;
export { Head } from '../components/seo/seo';
