// extracted by mini-css-extract-plugin
export var acceptButton = "index-module--acceptButton--b74f8";
export var body = "index-module--body--63dd3";
export var container = "index-module--container--9b019";
export var dismissButton = "index-module--dismissButton--b27da";
export var exitButton = "index-module--exitButton--4362d";
export var footer = "index-module--footer--7861b";
export var heading = "index-module--heading--6bf31";
export var icon = "index-module--icon--0d133";
export var padding = "index-module--padding--0fff2";
export var positioning = "index-module--positioning--14e21";
export var title = "index-module--title--d13bd";